import useAppContext from 'app/store/useAppContext'
import QuestionsAccordion from 'features/questions/accordion'
import classNames from 'shared/lib/class-names'

import cls from './Questions.module.scss'

export default function Questions() {
  const {
    store: { isMobileSmall },
  } = useAppContext()

  return (
    <section className={classNames('flex__col_center', cls.page)}>
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.header}>
          <h3 className={cls.header__title}>
            {isMobileSmall ? (
              <>Частые вопросы</>
            ) : (
              <>
                Часто задаваемые <span>вопросы</span>
              </>
            )}
          </h3>
        </div>
        <div className={cls.content}>
          <QuestionsAccordion className={cls.accordion} />
        </div>
      </div>
    </section>
  )
}
